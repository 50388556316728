<template>
  <div class="edit_roster">
    <asom-card>
      <asom-alert
        class="mb-4"
        v-if="warningAlert"
        variant="warning"
        :error-message="warningAlert"
      />
      <asom-alert
        class="mb-4"
        v-if="error"
        variant="error"
        :error-message="error"
      />
      <p>{{ v$.formData.planStart }}</p>
      <div v-if="isLoading" class="w-full text-center">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <form v-else class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field
          label="Officer"
          :state="inputStates('formData.officer')"
        >
          <asom-input-select
            v-model="formData.officer"
            disabled
            :options="[]"
            :state="inputStates('formData.officer')"
          />
        </asom-form-field>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            label="MRT Line"
            :state="inputStates('formData.lineId')"
          >
            <asom-input-select
              :objectModelValue="false"
              v-model="formData.lineId"
              disabled
              :options="lineOptions"
              :state="inputStates('formData.lineId')"
            />
          </asom-form-field>
          <asom-form-field
            label="MRT Station"
            :state="inputStates('formData.stationId')"
          >
            <asom-input-select
              :objectModelValue="false"
              v-model="formData.stationId"
              :options="stationOptions"
              :state="inputStates('formData.stationId')"
            />
          </asom-form-field>
        </div>
        <asom-form-field
          label="DWS"
          required
          :state="inputStates('formData.dws')"
        >
          <asom-input-select
            v-model="formData.dws"
            :options="dwsOptions"
            track-by="dws"
            :state="inputStates('formData.dws')"
          />
        </asom-form-field>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            v-if="!isOffRest"
            label="Starting Time"
            required
            :state="inputStates('formData.planStart')"
          >
            <asom-input-date-time
              v-model="formData.planStart"
              :state="inputStates('formData.planStart')"
            />
          </asom-form-field>
          <asom-form-field
            label="Ending Time"
            v-if="!isOffRest"
            required
            :state="inputStates('formData.planEnd')"
          >
            <asom-input-date-time
              v-model="formData.planEnd"
              :state="inputStates('formData.planEnd')"
            />
          </asom-form-field>
        </div>
        <asom-form-field
          v-if="!isOffRest"
          label="Time Duration"
          :state="inputStates('formData.planHours')"
        >
          <asom-input-text
            type="text"
            v-model="numberOfHours"
            :state="inputStates('formData.planHours')"
            :disabled="true"
          />
        </asom-form-field>
        <div v-if="!isOffRest"></div>
        <!-- <asom-form-field v-if="checkOTState">
          <asom-input-checkbox
            value="isOT"
            :checked="formData.shiftIsOt"
            @change="() => (formData.shiftIsOt = !formData.shiftIsOt)"
            label="Is OT?"
            description="Check if the shift is OT"
            :state="inputStates('formData.shiftIsOt')"
          />
        </asom-form-field>
        <div v-else /> -->
        <!-- <div></div> -->
        <asom-form-field
          required
          label="Remarks"
          :state="inputStates('formData.remarks')"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
      </form>
      <template #footer>
        <asom-button
          text="Cancel"
          variant="secondary"
          @click="$router.go(-1)"
        />
        <asom-button
          text="Submit"
          @click="submit(false)"
          :loading="isSubmitting"
        />
      </template>
    </asom-card>
    <asom-modal
      title-icon="error"
      title="Confirmation"
      v-model="showMaxOTHourModal"
      :dismissible="false"
    >
      <p>You are exceeding the max workable OT hours by MOM rule</p>
      <p>Do you want to continue?</p>
      <br />
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="submit(true)"
          :disabled="isSubmitting"
          :loading="isSubmitting"
          text="Confirm"
        />
        <asom-button
          @click="showMaxOTHourModal = false"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
import get from "lodash.get";
import moment from 'moment';
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import {
  getLineDws,
  getRosterDetails,
  editRoster,
} from "@/services/manpower.service";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      formData: {
        rosterId: this.$route.params.rosterId,
        officer: null,
        lineId: null,
        stationId: null,
        dws: null,
        planDate: null,
        planStart: null,
        planEnd: null,
        planHours: 0,
        shiftIsOt: false,
        remarks: null,
      },
      dwsOptions: [],
      isLoading: false,
      showMaxOTHourModal: false,
      error: null,
      isSubmitting: false,
      numberOfHours: "",
      warningAlert: null,
    };
  },
  validations() {
    return {
      formData: {
        rosterId: { required },
        officer: { required },
        lineId: { required },
        stationId: { required },
        dws: { required },
        planDate: { required },
        // planStart: {
        //   required: requiredUnless(function() {
        //     return !this.isOffRest
        //   })
        // },
        // planEnd: {
        //   required: requiredUnless(function() {
        //     return !this.isOffRest
        //   })
        // },
        planHours: { required },
        remarks: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      lineOptions: "smrtResource/lineOptions",
      canSelectLine: 'auth/canSelectLine',
    }),
    stationOptions() {
      return this.$store.getters["smrtResource/stationOptionsByLineId"](
        this.formData.lineId
      );
    },
    isOffRest() {
      return (
        this.formData.dws && ["OFF", "REST"].includes(this.formData.dws.dws)
      );
    },
    checkOTState() {
      const dws = get(this.formData.dws, "dws");
      return !["REST", "OFF"].includes(dws);
    },
  },
  mounted() {
    this.getRoster();
  },
  watch: {
    "formData.lineId": function(newValue, oldValue) {
      if (oldValue) this.formData.stationId = null;
      if (newValue) this.getDwsOptions();
    },
    "formData.dws": function(newValue) {
      if (newValue && newValue.shiftStart && newValue.shiftEnd) {
        const _planStart =
          this.formData.planDate.split(" ")[0] + " " + newValue.shiftStart;
        const _planEnd =
          this.formData.planDate.split(" ")[0] + " " + newValue.shiftEnd;
        const _planStartMoment = moment(_planStart, "DD/MM/YYYY HH:mm:ss");
        const _planEndMoment = moment(_planEnd, "DD/MM/YYYY HH:mm:ss");
        if (_planEndMoment.isBefore(_planStartMoment)) {
          _planEndMoment.add(1, "days");
        }
        this.formData.planStart = _planStartMoment.toDate();
        this.formData.planEnd = _planEndMoment.toDate();
      }
    },
    "formData.planStart": function(newValue) {
      if (moment(newValue).isValid()) {
        this.setHours();
      }
    },
    "formData.planEnd": function(newValue) {
      if (moment(newValue).isValid()) {
        this.setHours();
      }
    },
  },
  methods: {
    getDwsLabel(dws, shiftStart, shiftEnd) {
      return (
        dws +
        (shiftStart && shiftEnd
          ? " (" + shiftStart + " - " + shiftEnd + ")"
          : "")
      );
    },
    setFormData({
      officerId,
      officer,
      line,
      station,
      planDate,
      planStart,
      planEnd,
      dws,
      dwsText,
      hasExtendedOt,
      hasPlannedOt,
      otShift,
    }) {
      if (officerId) {
        this.setWarningMessages(hasExtendedOt, hasPlannedOt, otShift);
        this.formData.officer = {
          label: officer,
          value: officerId,
        };
        this.formData.lineId = get(line, "lineId");
        this.formData.stationId = get(station, "stationId");
        this.formData.dws = {
          label: this.getDwsLabel(
            dws,
            planStart ? planStart.split(" ")[1] : null,
            planEnd ? planEnd.split(" ")[1] : null
          ),
          dwsText: dwsText,
          dws: dws,
        };
        this.formData.planDate = planDate;
        if (planStart)
          this.formData.planStart = moment(
            planStart,
            "DD/MM/YYYY HH:mm:ss"
          ).toDate();
        if (planEnd)
          this.formData.planEnd = moment(
            planEnd,
            "DD/MM/YYYY HH:mm:ss"
          ).toDate();

        if (
          moment(this.formData.planStart).isValid() &&
          moment(this.formData.planEnd).isValid()
        ) {
          this.setHours();
        }
      }
    },
    setWarningMessages(hasExtendedOt, hasPlannedOt, otShift) {
      if (hasExtendedOt)
        this.warningAlert =
          "Staff has extended OT, Editing staff's roster will negate his OT shift";
      else if (hasPlannedOt)
        this.warningAlert =
          "Staff has planned OT, Editing staff's roster will negate his OT shift";
      else if (otShift)
        this.warningAlert =
          "Staff is on OT Shift. Editing staff's roster will negate his OT shift";
    },
    setHours() {
      let planStartMoment = moment(this.formData.planStart);
      let planEndMoment = moment(this.formData.planEnd);

      if (planStartMoment.isAfter(planEndMoment)) {
        planEndMoment.add(1, "days");
      }

      this.formData.planHours = planEndMoment.diff(
        planStartMoment,
        " Hours",
        true
      );

      let hoursString = planEndMoment.diff(planStartMoment, "hours");
      let minutesString =
        planEndMoment.diff(planStartMoment, "minutes") - hoursString * 60;
      let hourText = hoursString > 1 ? " Hours " : " Hour ";
      let minuteText = minutesString > 1 ? " Minutes" : " Minute";
      if (hoursString < 0 || minutesString < 0) {
        this.numberOfHours = "";
      } else {
        this.numberOfHours =
          hoursString + hourText + minutesString + minuteText;
      }
    },
    async getRoster() {
      this.isLoading = true;
      const result = await getRosterDetails(this.$route.params.rosterId);
      if (!result.success) {
        this.error = result.payload;
        this.isLoading = false;
        this.$scrollTop();
        return;
      }
      this.setFormData(get(result, "payload.data", {}));
      this.isLoading = false;
    },
    async getDwsOptions() {
      if (!this.formData.lineId || !this.formData.officer) return;
      const result = await getLineDws({
        lineId: this.formData.lineId,
        officerId: this.formData.officer.value,
      });
      if (result.success) {
        this.dwsOptions = get(result, "payload.list", []).map((item) => ({
          ...item,
          label: this.getDwsLabel(item.dws, item.shiftStart, item.shiftEnd),
        }));
      }
    },
    async submit(forced = false) {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (
        !this.v$.$invalid &&
        (this.isOffRest || this.formData.planHours > 0)
      ) {
        this.isSubmitting = true;
        const result = await editRoster({
          rosterId: this.formData.rosterId,
          officerId: this.formData.officer.value,
          stationId: this.formData.stationId,
          planDate: parseDateTime(this.formData.planDate, false),
          dws: this.formData.dws.dws,
          dwsText: this.formData.dws.dwsText,
          planStart: !this.isOffRest
            ? parseDateTime(this.formData.planStart)
            : null,
          planEnd: !this.isOffRest
            ? parseDateTime(this.formData.planEnd)
            : null,
          planHours: this.formData.planHours,
          shiftIsOt: this.formData.shiftIsOt,
          remarks: this.formData.remarks,
          forced: forced,
        });
        this.isSubmitting = false;
        if (result.success) this.$router.go(-1);
        else {
          this.error = result.payload;
          const forcible = get(result, "payload.forcible", false);
          if (forcible) {
            this.showMaxOTHourModal = true;
          }
          this.$scrollTop();
        }
      } else {
        this.error = "Please complete all required fields";
      }
    },
  },
};
</script>

<style scope>
.edit_roster .AsomCard__Body {
  @apply sm:rounded-tr-md sm:rounded-tl-md;
}
</style>
